import React, {useCallback, useEffect, useState} from "react";

export default function LookingGlass() {
    const [connection, setConnection] = useState({});
    const [selectedMethod, setSelectedMethod] = useState("ping");
    const [destinationIP, setDestinationIP] = useState("");

    const [selectedLocation, setSelectedLocation] = useState("uk");
    const [resultData, setResultData] = useState("");

    const locations = {
        'uk': {
            endpoint: "https://uk.lg.scaleblade.com",
            ips: {
                v4: '82.215.64.22',
                v6: '2a12:bec0:7000:1:82:215:64:22'
            }
        },
        'mci': {
            endpoint: 'https://mci.lg.scaleblade.com',
            ips: {
                v4: '185.248.134.9',
                v6: '2a12:bec3:b00b::101'
            }
        }
    }

    const location = locations[selectedLocation];

    useEffect(() => {
        fetch('https://api.scaleblade.com/user/connection')
            .then(resp => resp.json())
            .then(resp => {
                if(!resp.success) return

                setConnection(resp.connection);
            });
    }, []);

    const performAction = e => {
        e.preventDefault();

        setResultData("");

        let result = "";

        fetch(`${ location.endpoint }/${ selectedMethod }?host=${ destinationIP }`)
            .then(resp => resp.body)
            .then(resp => {
                const reader = resp.getReader();

                function readChunk() {
                    return reader.read().then(appendChunk);
                }

                function appendChunk({ done, value }) {
                    if(done) return

                    const chunk = new TextDecoder().decode(value);
                    result += chunk;
                    setResultData(result);

                    return readChunk();
                }

                return readChunk();
            })
            .catch(() => setResultData("An error occurred"));
    }

    return (
        <React.Fragment>
            <div className="absolute top-0 left-0 -z-10 w-full h-[28rem] bg-cover bg-no-repeat bg-center bg-[url('https://scaleblade.com/hero-bg.png')]" />
            <div className="max-w-screen-md mx-auto z-50 px-8 pt-16">
                <div className="flex justify-center py-16">
                    <img alt="logo.svg" src="https://scaleblade.com/logo-full.svg" className="h-16" />
                </div>
                <div className="mt-8 flex items-center">
                    <p className="text-gray-800 font-semibold text-gray-800 text-3xl">Network Looking Glass</p>
                    <div className="flex-1" />
                    <div className="flex flex-col items-end">
                        <p className="text-right text-gray-600 text-sm">IP Address</p>
                        <p>{ connection.ip || "Unknown" }</p>
                    </div>
                </div>
                <div className="bg-white p-8 mt-8 rounded shadow flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                        <p className="text-lg font-semibold text-gray-700">Datacenter Location</p>
                        <select onChange={e => setSelectedLocation(e.target.value)} className="border rounded p-4 focus:outline-none focus:border-gray-400 border-gray-300">
                            <option value="uk">London, United Kingdom</option>
                            <option value="mci">Kansas City, United States of America</option>
                        </select>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className="text-lg font-semibold text-gray-700">Network Information</p>
                        <p>Test IPv4: <span className="text-blue-600">{ location.ips.v4 }</span></p>
                        <p>Test IPv6: <span className="text-blue-600">{ location.ips.v6 }</span></p>
                        <p>Test Files: <a className="text-blue-600 hover:underline" target="_blank" href={`${ location.endpoint }/static/100MB.test`}>100MB</a> <a className="text-blue-600 hover:underline" target="_blank" href={`${ location.endpoint }/static/1GB.test`}>1GB</a></p>
                    </div>
                    <form onSubmit={performAction} className="grid grid-cols-4 gap-4">
                        <input placeholder="IP Address" value={destinationIP} onChange={e => setDestinationIP(e.target.value)} className="border border-gray-300 focus:border-gray-400 focus:outline-none px-4 py-2 rounded col-span-2" />
                        <select value={selectedMethod} onChange={e => setSelectedMethod(e.target.value)} className="border border-gray-300 focus:border-gray-400 focus:outline-none px-4 py-2 rounded text-gray-600">
                            <option value="ping">Ping</option>
                            <option value="traceroute">Traceroute</option>
                            <option value="bgp">BGP</option>
                        </select>
                        <button type="submit" className="text-xs sm:text-sm text-white font-semibold bg-blue-600/[95%] border border-blue-800 rounded-lg py-3 px-5 hover:-translate-y-[2%] transition duration-100">Start</button>
                    </form>
                    { resultData.length > 0 &&
                        <code className="bg-black p-4 rounded text-white whitespace-pre-wrap">
                            { resultData }
                        </code>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}